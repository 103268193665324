// Import the functions you need from the SDKs you need
import { FirebaseOptions, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig: FirebaseOptions = {
    apiKey: "AIzaSyDQVIKmeBhUVKPquezXJS-asFr7_XFbpgI",
    authDomain: "fir-labs-celestica.firebaseapp.com",
    projectId: "fir-labs-celestica",
    storageBucket: "fir-labs-celestica.appspot.com",
    messagingSenderId: "213718730418",
    appId: "1:213718730418:web:a83019805a3497d6752bab",
    measurementId: "G-KYFD4WME97"
  };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth( app );
export const firebaseStore = getFirestore( app );
export const analytics = getAnalytics(app);
