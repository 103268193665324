import { Button as BPButton, FormGroup, InputGroup } from "@blueprintjs/core";
import { Colors } from "../design/colors";
import { KeyboardEventHandler, useCallback, useMemo, useRef, useState } from "react";
import { AuthErrorCodes, createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { firebaseAuth } from "../setup/firebase";
import { FirebaseError } from "firebase/app";
import { CollectionId, setRecord } from "../utils/firestore";
import { appHost } from "../utils/common";
import { Button } from "./Button";
import { UnsupportedBrowser } from "./UnsupportedBrowser";
import "./Auth.css"

enum AuthMode {
    SignIn,
    SignUp,
    PasswordReset,
    PasswordResetLinkSent,
}

export const Auth = () => {
    const [ authMode, setAuthMode ] = useState<AuthMode>( AuthMode.SignIn );
    const [ email, setEmail ] = useState<string>( "" );
    const [ organizationName, setOrganizationName ] = useState<string>( "" );
    const [ password, setPassword ] = useState<string>( "" );
    const [ showPassword, setShowPassword ] = useState<boolean>( false );
    const [ processingRequest, setProcessingRequest ] = useState<boolean>( false );
    const [ authenticationError, setAuthenticationError ] = useState<string | null>( null );
    const [ passwordInput, setPasswordInput ] = useState<HTMLInputElement | null>( null );
    const authButtonRef = useRef<HTMLDivElement>( null );

    const navigate = ( authMode: AuthMode ) => {
        setAuthMode( authMode );
        setAuthenticationError( null );
    };

    let header: React.ReactNode;
    let action: React.ReactNode;
    let footerElement = null;
    switch ( authMode ) {
        case AuthMode.SignIn: {
            header = (
                <span className="sign-in-header"></span>
            );
            const disableAction = email === "" || password === "";
            const actionFn = async () => {
                setProcessingRequest( true );
                try {
                    await signInWithEmailAndPassword( firebaseAuth, email, password );
                } catch ( e ) {
                    if ( e instanceof FirebaseError ) {
                        setAuthenticationError( e.code );
                    }
                }
                setProcessingRequest( false );
            };
            action = (
                <Button
                    ref={ authButtonRef }
                    style={{ marginTop: 4 }}
                    backgroundColor={ Colors.BUTTON_RED }
                    textColor={ Colors.WHITE }
                    disabled={ disableAction || processingRequest }
                    onClick={ actionFn }
                >
                    Sign in
                </Button>
            );
            footerElement = (
                <div style={{ marginTop: 8, color: Colors.GREY, fontSize: 12 }}>
                    Don't have an account?&nbsp;
                    <span
                        style={{ color: Colors.LIGHT_BLUE, cursor: "pointer" }}
                        onClick={ () => navigate( AuthMode.SignUp ) }
                    >
                        Sign up
                    </span>
                </div>
            );
            break;
        }
        case AuthMode.SignUp: {
            header = (
                <span className="sign-up-header"></span>
            );
            const disableAction = email === "" || organizationName === "" || password === "";
            const actionFn = async () => {
                setProcessingRequest( true );
                try {
                    const res = await createUserWithEmailAndPassword( firebaseAuth, email, password );
                    await setRecord( CollectionId.Users, res.user.uid, { organizationName } );
                    await sendEmailVerification( res.user, { url: appHost } );
                } catch ( e ) {
                    if ( e instanceof FirebaseError ) {
                        setAuthenticationError( e.code );
                    }
                }
                setProcessingRequest( false );
            };
            action = (
                <Button
                    ref={ authButtonRef }
                    style={{ marginTop: 4 }}
                    backgroundColor={ Colors.BUTTON_RED }
                    textColor={ Colors.WHITE }
                    disabled={ disableAction || processingRequest }
                    onClick={ actionFn }
                >
                    Sign up
                </Button>
            );
            footerElement = (
                <div style={{ marginTop: 8, color: Colors.GREY, fontSize: 12 }}>
                    Already have an account?&nbsp;
                    <span
                        style={{ color: Colors.LIGHT_BLUE, cursor: "pointer" }}
                        onClick={ () => navigate( AuthMode.SignIn ) }
                    >
                        Sign in
                    </span>
                </div>
            );
            break;
        }
        case AuthMode.PasswordReset: {
            header = (
                <span className="reset-password-header"></span>
            );
            const disableAction = email === "";
            const actionFn = async () => {
                setProcessingRequest( true );
                try {
                    await sendPasswordResetEmail( firebaseAuth, email, { url: appHost } );
                    navigate( AuthMode.PasswordResetLinkSent );
                    setPassword( "" );
                } catch ( e ) {
                    if ( e instanceof FirebaseError ) {
                        setAuthenticationError( e.code );
                    }
                }
                setProcessingRequest( false );
            };
            action = (
                <Button
                    ref={ authButtonRef }
                    style={{ marginTop: 4 }}
                    backgroundColor={ Colors.BUTTON_RED }
                    textColor={ Colors.WHITE }
                    disabled={ disableAction || processingRequest }
                    onClick={ actionFn }
                >
                    Reset password
                </Button>
            );
            footerElement = (
                <div style={{ marginTop: 8, color: Colors.GREY, fontSize: 12 }}>
                    Back to&nbsp;
                    <span
                        style={{ color: Colors.LIGHT_BLUE, cursor: "pointer" }}
                        onClick={ () => navigate( AuthMode.SignIn ) }
                    >
                        sign in
                    </span>
                </div>
            );
            break;
        }
        case AuthMode.PasswordResetLinkSent: {
            header = (
                <>
                <span className="reset-password-header"></span>
                <span style={{ fontSize: 16, fontWeight: "bold", color: Colors.GREY, marginBottom: 16 }}>
                    Check your email for the reset link
                </span>
                </>
            );
            footerElement = (
                <div style={{ marginTop: 8, color: Colors.GREY, fontSize: 12 }}>
                    Back to&nbsp;
                    <span
                        style={{ color: Colors.LIGHT_BLUE, cursor: "pointer" }}
                        onClick={ () => navigate( AuthMode.SignIn ) }
                    >
                        sign in
                    </span>
                </div>
            );
            break;
        }
    }

    const handleEnterKey: KeyboardEventHandler<HTMLInputElement> = useCallback( ( e ) => {
        if ( e.key === "Enter" ) {
            e.preventDefault();
            e.currentTarget.blur();
            authButtonRef.current?.click();
        }
    }, [] );

    const onPasswordKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback( ( e ) => {
        handleEnterKey( e );
        if ( e.key === "Tab" ) {
            e.preventDefault();
            e.currentTarget.blur();
            authButtonRef.current?.focus();
        }
    }, [ handleEnterKey ] );

    const errorMessage = useMemo( () => {
        switch ( authenticationError ) {
            case null: return null;
            case AuthErrorCodes.INVALID_PASSWORD:
            case AuthErrorCodes.USER_DELETED: {
                if ( authMode === AuthMode.PasswordReset ) {
                    return "User not found";
                }
                return "Invalid user/password";
            }
            case AuthErrorCodes.EMAIL_EXISTS: return "User already exists";
            case AuthErrorCodes.INVALID_EMAIL: return "Invalid email format";
            case AuthErrorCodes.WEAK_PASSWORD: return "Weak password";
            default: return "Authentication error";
        }
    }, [ authenticationError, authMode ] );

    return (
        <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <div
                style={{
                    display: "flex",
                    backgroundColor: Colors.WHITE,
                    padding: 32,
                    boxShadow: "1px 2px 9px #222222",
                    borderRadius: 16,
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                { header }
                {
                    authMode !== AuthMode.PasswordResetLinkSent ?
                    <FormGroup style={{ width: passwordInput?.clientWidth }}>
                        <InputGroup
                            style={{ backgroundColor: Colors.WHITE, color: Colors.GREY }}
                            placeholder="Email"
                            type="email"
                            value={ email }
                            onKeyDown={ handleEnterKey }
                            onChange={ e => setEmail( e.target.value ) }
                        />
                    </FormGroup> :
                    null
                }
                {
                    authMode === AuthMode.SignUp ?
                    <FormGroup style={{ width: passwordInput?.clientWidth }}>
                        <InputGroup
                            style={{ backgroundColor: Colors.WHITE, color: Colors.GREY }}
                            placeholder="Organization"
                            value={ organizationName }
                            onKeyDown={ handleEnterKey }
                            onChange={ e => setOrganizationName( e.target.value ) }
                        />
                    </FormGroup> :
                    null
                }
                {
                    authMode === AuthMode.SignIn || authMode === AuthMode.SignUp ?
                    <FormGroup
                        helperText={
                            authMode === AuthMode.SignIn ?
                            <span
                                style={{
                                    marginTop: 16,
                                    color: Colors.LIGHT_BLUE,
                                    cursor: "pointer",
                                }}
                                onClick={ () => navigate( AuthMode.PasswordReset ) }
                            >
                                Forgot password?
                            </span> :
                            undefined
                        }
                    >
                        <InputGroup
                            inputRef={ setPasswordInput }
                            style={{ backgroundColor: Colors.WHITE, color: Colors.GREY }}
                            type={ showPassword ? undefined : "password" }
                            placeholder="Password"
                            value={ password }
                            onChange={ e => setPassword( e.target.value ) }
                            onKeyDown={ onPasswordKeyDown }
                            rightElement={
                                <BPButton
                                    minimal={ true }
                                    style={{ color: Colors.GREY }}
                                    intent="primary"
                                    icon={ showPassword ? "eye-off" : "eye-open" }
                                    onClick={ () => setShowPassword( v => !v ) }
                                />
                            }
                        />
                    </FormGroup> :
                    null
                }
                { action }
                <div style={{ marginTop: 4, color: Colors.YELLOW, maxWidth: passwordInput?.clientWidth }}>
                    { errorMessage }
                </div>
                { footerElement }
                { ( navigator['hid'] === undefined ) && (
                  <div style={{ marginTop: 16 }} >
                    <UnsupportedBrowser />
                  </div>
                ) }
            </div>
        </div>
    );
};
