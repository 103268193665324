import { useCallback, useState } from "react";
import { Checkbox, Dialog, InputGroup, Radio, RadioGroup, Label } from "@blueprintjs/core";
import { ExportType, downloadText } from "../../utils/hid-export";
import { Colors } from "../../design/colors";
import { PacketId } from "../../utils/common-types";
import { ProxyMarked, proxy } from "comlink";
import { usePacketMetadata } from "../../hooks/usePacketMetadata";
import { TimeWindow, usePlayback } from "../../hooks/usePlayback";
import { ClickableIcon } from "../ClickableIcon";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { packetThreadPoolApi, realtimeMetadata } from "../../utils/packet-workers";
import { Button } from "../Button";

const defaultFilename = () => {
    const date = new Date();
  
    return `recording-${date.toISOString().replace(/[-:.]/g, "").slice(0, -4)}`;
  };

export const FileExport = ( props: {
    packetId: PacketId;
    onClose: () => void;
} ) => {
    const { packetId, onClose } = props;
    const { timeWindow } = usePlayback( props.packetId );
    const [ exportSize, setExportSize ] = useState<"all" | "selection">( "all" );
    const [ isExporting, setIsExporting ] = useState( false );

    // form state
    const [ filename, setFilename ] = useState( defaultFilename() );
    const [ playbackChecked, setPlaybackChecked ] = useState( true );
    const [ csvChecked, setCsvChecked ] = useState( false );

    const exportEnabled = 
        filename.length > 0 && ( csvChecked || playbackChecked ) && !isExporting;

    const handleExport = useCallback( 
        async (
            downloadFn: ((filename: string, text: string) => void) & ProxyMarked, 
            exportWindow: TimeWindow
        ) => {

        setIsExporting(true);
        try {
            if (playbackChecked) {
                await packetThreadPoolApi.export(
                    packetId,
                    filename,
                    ExportType.Playback,
                    exportWindow,
                    downloadFn
                );
            }

            if (csvChecked) {
                await packetThreadPoolApi.export(
                    packetId,
                    filename,
                    ExportType.CSV,
                    exportWindow,
                    downloadFn
                );
            }
        } finally {
            setIsExporting(false);
            onClose();
        }
    }, [csvChecked, filename, onClose, packetId, playbackChecked] );

    const onExportClick = useCallback( () => {
        if ( filename.length === 0 ) {
            return;
        }

        let exportWindow: TimeWindow;
        if ( exportSize === "all" ) {
            exportWindow = realtimeMetadata.timeWindow || [ 0, Infinity ];
        } else if ( timeWindow === undefined ) {
          return;
        } else {
          exportWindow = timeWindow;
        }

        const downloadFn = proxy( downloadText );
        
        return handleExport( downloadFn, exportWindow );
        
    }, [filename.length, timeWindow, exportSize, handleExport] );


    return (
        <Dialog
          title="Export"
          isOpen={true}
          onClose={props.onClose}
          style={{ backgroundColor: Colors.WHITE }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: 30,
            }}
          >
            <InputGroup
              placeholder="Name"
              value={filename}
              onChange={(e) => setFilename(e.currentTarget.value)}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 20,
                color: Colors.GREY,
              }}
            >
              {/* Data to export select area */}
              <div style={{ marginBottom: 10 }}>
                <Label style={{ marginBottom: 10 }}>What to export</Label>
                <RadioGroup
                  inline={false}
                  selectedValue={exportSize}
                  onChange={(e) =>
                    setExportSize(e.currentTarget.value as "all" | "selection")
                  }
                >
                  <Radio label="All data points" value="all" />
                  <Radio
                    label="Data points in view"
                    value="selection"
                    disabled={timeWindow === undefined}
                  />
                </RadioGroup>
              </div>
              {/* File type select area */}
              <div style={{ marginBottom: 10 }}>
                <Label style={{ marginBottom: 10 }}>File type(s)</Label>
                <Checkbox
                  inline={false}
                  label="Playback file"
                  checked={playbackChecked}
                  onChange={(e) => setPlaybackChecked(e.currentTarget.checked)}
                />
                <Checkbox
                  inline={false}
                  label="CSV"
                  checked={csvChecked}
                  onChange={(e) => setCsvChecked(e.currentTarget.checked)}
                />
              </div>
            </div>
            <div style={{ alignSelf: "flex-end", display: "flex" }}>
              <Button
                backgroundColor={Colors.GREY}
                textColor={Colors.WHITE}
                style={{ marginRight: 10 }}
                onClick={props.onClose}
              >
                Cancel
              </Button>
              <Button
                backgroundColor={Colors.LIGHT_BLUE}
                textColor={Colors.WHITE}
                disabled={!exportEnabled}
                onClick={onExportClick}
              >
                {isExporting ? "Exporting..." : "Export"}
              </Button>
            </div>
          </div>
        </Dialog>
      );
};

export const ExportButton = () => {
    const [ exportDialogOpen, setExportDialogOpen ] = useState( false );
    const totalPackets = usePacketMetadata().length;
    
    if ( totalPackets === 0 ) {
        return null;
    }

    return (
        <>
            { exportDialogOpen && <FileExport packetId={ PacketId.REALTIME_DATA } onClose={ () => setExportDialogOpen( false ) } /> }
            <ClickableIcon
                icon={ faDownload }
                title="Export"
                color={ Colors.GREY }
                size="xl"
                onClick={ () => setExportDialogOpen( true ) }
            />
        </>
    );
};