// export enum Colors {
//     RED = "#f73e31",
//     BUTTON_GREEN = "rgba(53,89,62,1)",
//     LIGHT_LIGHT_GREEN = "rgba(141,227,190,1)",
//     LIGHT_GREEN = "rgba(26,42,35,1)",
//     DARK_GREEN = "rgba(18,34,27,1)",
//     YELLOW = "#fad637",
//     BLACK = "#000000",
//     WHITE = "#F6F7EB",
// }

// export enum Colors {
//     RED = "#f73e31",
//     BUTTON_GREEN = "#D50032",
//     LIGHT_LIGHT_GREEN = "#53565A",
//     LIGHT_GREEN = "#FFFFFF",
//     DARK_GREEN = "#FFFFFF",
//     YELLOW = "#fad637",
//     BLACK = "#F6F7EB",
//     WHITE = "#53565A",
//     // WHITE = "#F6F7EB",
//     LIGHT_BLUE = "#298FC2",
// }
export enum Colors {
    RED = "#D50032",
    BUTTON_RED = "#D50032",
    GREY = "#53565A",
    LIGHT_GREY = "rgba(162,172,171,0.2)",
    LIGHT_SOLID_GREY = "rgba(151,153,155)",
    YELLOW = "#FFB81C",
    WHITE = "#FFFFFF",
    LIGHT_BLUE = "#298FC2",
    BLACK = "#000000",
    BUTTON_GREEN = "#D50032",
    LIGHT_LIGHT_GREEN = "#53565A",
    LIGHT_GREEN = "#FFFFFF",
    DARK_GREEN = "#FFFFFF",
    // WHITE = "#F6F7EB",
}