import { useCallback, useEffect, useRef, useState } from "react";
import { WidgetPanel } from "../../../../../components/widgets/WidgetPanel";
import { ConnectionType, useConnection } from "../../../../../connections/connection";
import { PacketId } from "../../../../../utils/common-types";
import { Colors } from "../../../../../design/colors";
import { usePlayback } from "../../../../../hooks/usePlayback";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlugCircleExclamation, faPlugCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { useDevices } from "../../../../../hooks/useDevices";
import { FileExport } from "../../../../../components/navbar/ExportButton";

const stream = false;

export const Dashboard = ( props: {
    manageConfigurations: () => void;
} ) => {
    const inputRef = useRef<HTMLInputElement>( null );
    const { connection, connect } = useConnection();
    const { connectedDevice, triggerDisconnectExport, onDisconnect} = useDevices();
    const [ showExport, setShowExport ] = useState(false);
    const { live } = usePlayback( PacketId.REALTIME_DATA );

    /* Add event listener for HID disconnect to Dashboard instead of useDevices hook
     * and call onDisconnect from useDevices hook to avoid circular dependency. Calling the hook directly
     * allows the hook to update the triggerDisconnectExport state variable which is used to trigger the
     * export dialog.
     */
    if ( navigator['hid'] ) {
      ( navigator.hid.ondisconnect as ( e: HIDConnectionEvent ) => void ) = (e) => onDisconnect(e);
    }

    useEffect(() => {
        if (triggerDisconnectExport) {
            setShowExport(true);
        }
    }, [triggerDisconnectExport]);

    const handleInputChange = useCallback( async ( e: React.FormEvent<HTMLInputElement> ) => {
        const files = e.currentTarget.files;
        if ( !isDefined( files ) || files.length === 0 ) {
            return;
        }

        const file = files[ 0 ];
        await connect({ connectionType: ConnectionType.HIDPlayback, file, stream });
        if ( stream ) {
            live();
        }

        if ( inputRef.current ) {
            inputRef.current.value = "";
        }
    }, [ connect, live ] );


    if ( connectedDevice === undefined && connection?.connectionType !== ConnectionType.HIDPlayback ) {
        return (
            <>
                <input ref={ inputRef } style={{ display: "none" }} type="file" accept=".slnt" onInput={ handleInputChange } />
                <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", color: Colors.GREY }}>
                    <FontAwesomeIcon
                        icon={ faPlugCircleXmark }
                        size="5x"
                        color={ Colors.GREY }
                        style={{ marginBottom: 32 }}
                    />
                    <div style={{ fontSize: 24, fontWeight: "bold", marginBottom: 16 }}>Not connected.</div>
                    <div style={{ fontSize: 16 }}><span style={{ color: Colors.LIGHT_BLUE, cursor: "pointer" }} onClick={ props.manageConfigurations }>Connect a controller</span> or <span style={{ color: Colors.LIGHT_BLUE, cursor: "pointer" }} onClick={ () => inputRef?.current?.click() }>load a playback file</span>.</div>
                </div>
                { showExport && <FileExport packetId={ PacketId.REALTIME_DATA } onClose={ () => setShowExport(false) } /> }
            </>
        );
    } else if ( connectedDevice !== undefined && connectedDevice.initialized && connectedDevice.activeConfigurationId === "" ) {
        return (
            <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", color: Colors.GREY }}>
                <FontAwesomeIcon
                    icon={ faPlugCircleExclamation }
                    size="5x"
                    color={ Colors.YELLOW }
                    style={{ marginBottom: 32 }}
                />
                <div style={{ fontSize: 24, marginBottom: 16 }}>Your <span style={{ fontWeight: "bold" }}>{ connectedDevice.handle.productName }</span> is not yet configured.</div>
                <div style={{ fontSize: 16, color: Colors.LIGHT_BLUE, cursor: "pointer" }} onClick={ () => props.manageConfigurations() }>Manage Motor Configurations</div>
            </div>
        );
    }

    return <WidgetPanel />;
}
