import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Dialog, Spinner, Icon, Intent } from "@blueprintjs/core";
import { Popover2, Classes } from "@blueprintjs/popover2";

import { Colors } from "../../../../../design/colors";
import { useReset } from "../../../../../hooks/useReset";
import { usePlayback } from "../../../../../hooks/usePlayback";
import {
  Device,
  MIN_FW_VERSION,
  useDevices,
} from "../../../../../hooks/useDevices";
import {
  Configuration,
  useMotorConfigurations,
} from "../../../../../hooks/useMotorConfigurations";
import {
  ControlMode,
  InputMode,
  PacketId,
  SmFwBuildType
} from "../../../../../utils/common-types";
import { CollectionId, deleteRecord } from "../../../../../utils/firestore";
import { versionLessThan, buildTypeIntToString } from "../../../../../utils/common";
import {
  flashFirmware,
  supportsDFU,
} from "../../../../../connections/controller";
import { Button } from "../../../../../components/Button";
import { ThrottleRangeDisplay } from "../../../../../components/ThrottleRangeDisplay";
import { CreateConfiguration } from "./screens/CreateConfiguration";
import "./screens/CreateConfiguration.css";
import { UnsupportedBrowser } from "../../../../../components/UnsupportedBrowser";

const inputModeToString = (inputMode: InputMode) => {
  switch (inputMode) {
    case InputMode.SERVO:
      return "Servo";
    case InputMode.CAN:
      return "CAN";
    case InputMode.USB:
      return "USB";
    default:
      return "Invalid";
  }
};

const controlModeToString = (controlMode: ControlMode) => {
  switch (controlMode) {
    case ControlMode.TORQUE:
      return "Torque";
    case ControlMode.SPEED:
      return "Speed";
    case ControlMode.VOLTAGE:
      return "Voltage";
    case ControlMode.POSITION:
      return "Position";
  }
};

const DeleteConfigurationPopover = (props: { onDelete: () => void }) => {
  return (
    <Popover2
      content={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 16,
            backgroundColor: Colors.WHITE,
            borderRadius: 8,
          }}
        >
          <div style={{ color: Colors.GREY, marginBottom: 8 }}>
            Are you sure you want to delete this configuration? This cannot be
            undone.
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {/* Required for popover dismiss to work */}
            <div className={Classes.POPOVER2_DISMISS}>
              <Button backgroundColor={Colors.GREY} textColor={Colors.WHITE}>
                Cancel
              </Button>
            </div>
            <div style={{ width: 8 }} />
            <div className={Classes.POPOVER2_DISMISS}>
              <Button
                backgroundColor={Colors.BUTTON_RED}
                textColor={Colors.WHITE}
                onClick={props.onDelete}
              >
                Confirm delete
              </Button>
            </div>
          </div>
        </div>
      }
      placement="auto"
    >
      <Button backgroundColor={Colors.BUTTON_RED} textColor={Colors.WHITE}>
        Delete
      </Button>
    </Popover2>
  );
};

const ConfigurationItem = (props: {
  configuration: Configuration;
  apply?: () => void;
  edit: () => void;
  active: boolean;
}) => {
  const { active, configuration } = props;
  const { inputConfig, controlConfig, powerConfig } = props.configuration.data;
  const { fetchConfigurations } = useMotorConfigurations();

  const deleteConfig = useCallback(async () => {
    await deleteRecord(CollectionId.Configurations, configuration.id);
    await fetchConfigurations();
  }, [configuration, fetchConfigurations]);

  return (
    <div
      style={{
        flex: "1 1 0px",
        display: "flex",
        padding: 16,
        color: Colors.GREY,
        fontSize: 16,
        backgroundColor: Colors.LIGHT_GREY,
        boxShadow: "0px 0px 9px " + ( active ? Colors.LIGHT_BLUE : "#222222" ),
        marginBottom: 24,
        justifyContent: "space-between",
        borderRadius: 8,
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            flex: "1 1 0px",
            fontSize: 18,
            fontWeight: "bold",
            marginBottom: 8,
          }}
        >
          {props.configuration.data.details.name}
        </div>
        <div style={{ fontSize: 12, marginBottom: 16 }}>
          Battery: {powerConfig.batteryCurrentLimit}A, Motor:{" "}
          {powerConfig.motorCurrentLimit}A, Overtemp:{" "}
          {powerConfig.overtemperatureThreshold}C
        </div>
        <div style={{ fontSize: 12, marginBottom: 4 }}>
          {inputModeToString(inputConfig.inputMode)} input /{" "}
          {controlModeToString(controlConfig.controlMode)} control
        </div>
        <div style={{ flex: "1 1 0px" }}>
          <ThrottleRangeDisplay
            powerConfig={ powerConfig }
            controlConfig={ controlConfig }
            inputConfig={ inputConfig }
          />
        </div>
      </div>
      <div style={{ width: 32 }} />
      <div>
        <Button
          backgroundColor={Colors.LIGHT_BLUE}
          textColor={Colors.WHITE}
          onClick={props.apply}
          disabled={props.apply === undefined}
        >
          Apply
        </Button>
        <div style={{ height: 8 }} />
        <Button
          backgroundColor={Colors.GREY}
          textColor={Colors.WHITE}
          onClick={props.edit}
        >
          Edit
        </Button>
        <div style={{ height: 8 }} />
        {/* Delete popover */}
        <DeleteConfigurationPopover onDelete={deleteConfig} />
      </div>
    </div>
  );
};

const MotorConfigurations = (props: { createConfiguration: () => void }) => {
  const {
    configurations,
    initialized,
    fetchConfigurations,
    selectConfiguration,
  } = useMotorConfigurations();
  const { connectedDevice, applyConfiguration: _applyConfiguration } =
    useDevices();
  const [writeInProgress, setWriteInProgress] = useState<boolean>(false);
  const { createConfiguration } = props;

  useEffect(() => {
    fetchConfigurations();
  }, [fetchConfigurations]);

  const applyConfiguration = useCallback(
    async (configuration: Configuration) => {
      if (connectedDevice === undefined) {
        return;
      }

      setWriteInProgress(true);
      try {
        await _applyConfiguration?.(configuration);
      } finally {
        setWriteInProgress(false);
      }
    },
    [connectedDevice, _applyConfiguration]
  );

  const configurationEls = useMemo(() => {
    if (!initialized) {
      return null;
    }

    if (configurations.length === 0) {
      return (
        <div style={{ color: Colors.WHITE }}>No configurations found.</div>
      );
    }

    return configurations.map((configuration, index) => {
      const active =
        !!connectedDevice?.initialized &&
        connectedDevice.activeConfigurationId === configuration.id;
      const disabled =
        connectedDevice === undefined ||
        !connectedDevice.initialized ||
        writeInProgress;

      return (
        <ConfigurationItem
          key={`config-${index}`}
          configuration={configuration}
          apply={disabled ? undefined : () => applyConfiguration(configuration)}
          edit={() => {
            selectConfiguration(configuration.id);
            createConfiguration();
          }}
          active={active}
        />
      );
    });
  }, [
    initialized,
    applyConfiguration,
    createConfiguration,
    configurations,
    selectConfiguration,
    connectedDevice,
    writeInProgress,
  ]);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 32,
      }}
    >
      <div
        style={{
          fontSize: 24,
          fontWeight: "bold",
          color: Colors.GREY,
          marginBottom: 32,
        }}
      >
        <div style={{ marginBottom: 16 }}>Motor Configurations</div>
        <Button
          backgroundColor={Colors.BUTTON_RED}
          textColor={Colors.WHITE}
          onClick={createConfiguration}
          disabled={connectedDevice === undefined}
        >
          New
        </Button>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {configurationEls}
      </div>
    </div>
  );
};

interface IFirmwareUpdateState {
  device: HIDDevice;
  file: File;
}

const ControllerItem = (props: {
  device: Device;
  setFirmwareUpdateState: (state: IFirmwareUpdateState | undefined) => void;
  mainColor: string;
  mainAction: () => void;
  mainText: string;
  mainActionDisabled: boolean;
  activeConfiguration: Configuration | undefined;
  isCycloneConnected: boolean;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { device, setFirmwareUpdateState, isCycloneConnected } = props;

  let firmwareSubText;
  if ( device.initialized ) {
    if ( versionLessThan(device.fwVersion, MIN_FW_VERSION) ) {
      firmwareSubText = `>= ${MIN_FW_VERSION.join(".")} required`;
    } else if ( device.needToUpdate ) {
      // General update notice without specifying a version
      firmwareSubText = "Update required";
    }
  }

  const handleInputChange = useCallback(
    async (e: React.FormEvent<HTMLInputElement>) => {
      const files = e.currentTarget.files;
      if (files === null || files.length === 0) {
        return;
      }

      const file = files[0];
      setFirmwareUpdateState({
        device: device.handle,
        file,
      });

      if (inputRef.current !== null) {
        inputRef.current.value = "";
      }
    },
    [device, setFirmwareUpdateState]
  );

  // Devices are always initialized, but TypeScript doesn't know that
  if ( !device.initialized ) return <></>;

  const isBuildInfoAvail: boolean = device.buildInfo !== undefined;
  const buildTypeString: string = buildTypeIntToString(device.buildInfo?.buildType);
  const buildYear: number = (device.buildInfo === undefined) ? 0 : device.buildInfo?.year;
  const buildMonth: number = (device.buildInfo === undefined) ? 0 : device.buildInfo?.month;
  const buildDay: number = (device.buildInfo === undefined) ? 0 : device.buildInfo?.day;
  const buildIncrement: number = (device.buildInfo === undefined) ? 0 : device.buildInfo?.increment;
  const isNotReleaseBuild: boolean = (device.buildInfo === undefined)
                                        ? false
                                        : ( device.buildInfo?.buildType !== SmFwBuildType.RELEASE );

  return (
    <>
      <div
        style={{
          flex: "1 1 0px",
          display: "flex",
          padding: 16,
          color: Colors.GREY,
          fontSize: 16,
          backgroundColor: Colors.LIGHT_GREY,
          boxShadow: "1px 2px 9px #222222",
          marginBottom: 24,
          borderRadius: 8,
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div style={{ fontSize: 18, fontWeight: "bold", marginBottom: 2 }}>
              {device.handle.productName.replace("Salient Motion", "")}
            </div>
            <div style={{ fontSize: 12, opacity: 0.75 }}>
              {device.mcuSerialNum}
            </div>
            <div style={{ fontSize: 18, opacity: 0.90, marginTop: 10 }}>
              {isBuildInfoAvail && isNotReleaseBuild
              ? `${buildTypeString} ${buildYear}.${buildMonth}.${buildDay}.${buildIncrement}`
              : undefined}
            </div>
            <div style={{ fontSize: 18, opacity: 0.90, marginTop: 10, color: Colors.RED }}>
              {isBuildInfoAvail && device.buildInfo?.dirty === true
              ? `Compiled with uncommitted changes!`
              : undefined}
            </div>
          </div>
          {props.activeConfiguration === undefined ? null : (
            <div style={{ fontSize: 12 }}>
              Active configuration:{" "}
              <span style={{ fontWeight: "bold" }}>
                {props.activeConfiguration.data.details.name}
              </span>
            </div>
          )}
        </div>
        <div style={{ width: 64 }} />
        <div>
          <Button
            backgroundColor={props.mainColor}
            textColor={Colors.WHITE}
            onClick={props.mainAction}
            disabled={props.mainActionDisabled}
          >
            {props.mainText}
          </Button>
          <div style={{ height: 8 }} />
          <Button
            backgroundColor={Colors.GREY}
            textColor={Colors.WHITE}
            onClick={() => inputRef.current?.click()}
          >
            <input
              ref={inputRef}
              style={{ display: "none" }}
              type="file"
              onInput={handleInputChange}
              accept=".bin"
            />
            Flash Firmware
          </Button>
          <div style={{ fontSize: 14, marginTop: 2 }}>
          Current: v{device.fwVersion.join(".")}
          </div>
          <div style={{ fontSize: 14, opacity: 0.90, textAlign: "center" }}>
            {isBuildInfoAvail
            ? `${device.buildInfo?.gitCommitSHA1.toString(16)}`
            : undefined}
          </div>
          {firmwareSubText && (
            <div style={{ fontSize: 12, marginTop: 2, color: Colors.YELLOW }}>
              {firmwareSubText}
            </div>
          )}
        </div>
      </div>
      {isCycloneConnected ? (
        <WarningMessage
          title="Note: Your Cyclone evaluation kit is in training wheels mode."
          message="To unlock faster ramping, higher current limits, or a custom CAN protocol, reach out to the Salient Motion team."
        />
      ) : null}
    </>
  );
};

const WarningMessage = (props: { title: string; message: string }) => {
  return (
    <div
      style={{
        backgroundColor: Colors.WHITE,
        padding: 16,
        display: "flex",
        flexDirection: "column",
        whiteSpace: "pre-wrap",
        width: "75%",
        margin: "0 auto",
      }}
    >
      <div
        style={{
          color: Colors.GREY,
          fontSize: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Icon
          icon="warning-sign"
          intent={Intent.WARNING}
          size={20}
          style={{
            marginRight: 8,
          }}
        />{" "}
        {props.title}
      </div>
      <div
        style={{
          color: Colors.YELLOW,
          fontSize: 16,
          display: "flex",
          justifyContent: "center",
          marginTop: 16,
        }}
      >
        {props.message}
      </div>
    </div>
  );
};

export const Controllers = () => {
  const reset = useReset();
  const { devices, connectedDevice, connect, pairDevice } = useDevices();
  const { configurations } = useMotorConfigurations();
  const { live } = usePlayback(PacketId.REALTIME_DATA);
  const [firmwareUpdateState, setFirmwareUpdateState] = useState<
    IFirmwareUpdateState | undefined
  >();
  const [flashProgress, setFlashProgress] = useState<number | undefined>();

  const unsupportedBrowser = ( navigator['hid'] === undefined );

  const updateDialog = useMemo(() => {
    if (firmwareUpdateState === undefined) {
      return null;
    }

    const { device, file } = firmwareUpdateState;

    const flash = async () => {
      const { device, file: binary } = firmwareUpdateState;

      try {
        await flashFirmware(device, binary, setFlashProgress);
        setFirmwareUpdateState(undefined);
      } finally {
        setFlashProgress(undefined);
      }
    };

    const progressSpinner =
      flashProgress === undefined ? null : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 32,
          }}
        >
          <Spinner intent="success" value={flashProgress} />
          <div style={{ height: 8 }} />
          <div style={{ color: Colors.GREY }}>
            {Math.floor(flashProgress * 100)}%
          </div>
        </div>
      );

    return (
      <Dialog
        style={{
          backgroundColor: Colors.WHITE,
          padding: 16,
          display: "flex",
          flexDirection: "column",
          whiteSpace: "nowrap",
          width: "fit-content",
        }}
        isOpen={true}
        onClose={
          flashProgress === undefined
            ? () => setFirmwareUpdateState(undefined)
            : undefined
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            margin: "16px 32px 32px 32px",
          }}
        >
          <div
            style={{
              color: Colors.GREY,
              fontSize: 20,
              display: "flex",
              justifyContent: "center",
            }}
          >
            Flash&nbsp;
            <span style={{ fontWeight: "bold" }}>{device.productName}</span>
            &nbsp;with&nbsp;
            <span style={{ fontWeight: "bold" }}>{file.name}</span>?
          </div>
          {supportsDFU(device) ? (
            <div
              style={{
                color: Colors.YELLOW,
                fontSize: 16,
                display: "flex",
                justifyContent: "center",
                marginTop: 16,
              }}
            >
              After clicking "Flash", you'll be asked to select a device.
              Select&nbsp;
              <span style={{ fontWeight: "bold" }}>DFU in FS Mode</span>.
            </div>
          ) : null}
        </div>
        {progressSpinner === null ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 16,
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                backgroundColor={Colors.GREY}
                textColor={Colors.WHITE}
                onClick={() => setFirmwareUpdateState(undefined)}
              >
                Cancel
              </Button>
            </div>
            <div style={{ width: 8 }} />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                backgroundColor={Colors.BUTTON_RED}
                textColor={Colors.WHITE}
                onClick={flash}
              >
                Flash
              </Button>
            </div>
          </div>
        ) : (
          progressSpinner
        )}
      </Dialog>
    );
  }, [flashProgress, setFlashProgress, firmwareUpdateState]);

  const deviceEls = useMemo(() => {
    if (devices === undefined) {
      return null;
    }

    if (devices.length === 0) {
      return <div style={{ color: Colors.WHITE }}>No controllers found.</div>;
    }

    return devices.map((device, index) => {
      const isConnected =
        connectedDevice?.initialized &&
        device.initialized &&
        connectedDevice.mcuSerialNum === device.mcuSerialNum;
      const activeConfiguration = device.initialized
        ? configurations.find((c) => c.id === device.activeConfigurationId)
        : undefined;
      const disabled =
        !device.initialized ||
        versionLessThan(device.fwVersion, MIN_FW_VERSION);
      const isCycloneConnected =
        device.handle.productName === "Cyclone Controller";

      return (
        <ControllerItem
          key={"device-" + index}
          device={device}
          setFirmwareUpdateState={setFirmwareUpdateState}
          mainColor={isConnected ? Colors.BUTTON_RED : Colors.LIGHT_BLUE}
          mainAction={
            isConnected
              ? reset
              : async () => {
                  await reset();
                  connect(device);
                  live();
                }
          }
          mainActionDisabled={disabled}
          mainText={isConnected ? "Disconnect" : "Connect"}
          activeConfiguration={activeConfiguration}
          isCycloneConnected={isCycloneConnected}
        />
      );
    });
  }, [configurations, connectedDevice, devices, connect, reset, live]);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 32,
      }}
    >
      {updateDialog}
      <div
        style={{
          fontSize: 24,
          fontWeight: "bold",
          color: Colors.GREY,
          marginBottom: 32,
        }}
      >
        <div style={{ marginBottom: 16 }}>Paired Controllers</div>
        <Button
          backgroundColor={Colors.BUTTON_RED}
          textColor={Colors.WHITE}
          disabled={ unsupportedBrowser }
          onClick={ unsupportedBrowser ? undefined : pairDevice }
        >
          Pair
        </Button>
      </div>
      { unsupportedBrowser && <UnsupportedBrowser /> }
      <div style={{ display: "flex", flexDirection: "column" }}>
        {deviceEls}
      </div>
    </div>
  );
};

enum Screens {
  Main,
  CreateConfiguration,
}

export const Devices = () => {
  const [screen, setScreen] = useState<Screens>(Screens.Main);
  const { selectedConfiguration, selectConfiguration } =
    useMotorConfigurations();

  if (screen === Screens.CreateConfiguration) {
    return (
      <CreateConfiguration
        configuration={selectedConfiguration}
        exit={() => {
          setScreen(Screens.Main);
          selectConfiguration("");
        }}
      />
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <Controllers />
      <MotorConfigurations
        createConfiguration={() => setScreen(Screens.CreateConfiguration)}
      />
    </div>
  );
};
