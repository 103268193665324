import { useCallback, useEffect, useState } from "react";
import { Colors } from "../../../../../design/colors";
import { useAuth } from "../../../../../hooks/useAuth";
import { CollectionId, DatabaseRecord, getRecord } from "../../../../../utils/firestore";
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import { Button as BPButton, Dialog, FormGroup, InputGroup } from "@blueprintjs/core";
import { Button } from "../../../../../components/Button";

const PasswordChangeDialog = ( props: {
    isOpen: boolean;
    onClose: () => void;
} ) => {
    const { user } = useAuth();

    const [ currentPassword, setCurrentPassword ] = useState<string>( "" );
    const [ showCurrentPassword, setShowCurrentPassword ] = useState<boolean>( false );

    const [ newPassword, setNewPassword ] = useState<string>( "" );
    const [ showNewPassword, setShowNewPassword ] = useState<boolean>( false );

    const [ success, setSuccess ] = useState<boolean>( false );

    const { onClose } = props;

    const updateUserPassword = useCallback( async () => {
        if ( !user || user.email === null ) {
            return;
        }

        try {
            const credential = EmailAuthProvider.credential( user.email, currentPassword );
            await reauthenticateWithCredential( user, credential );
            await updatePassword( user, newPassword );
            setSuccess( true );
            setNewPassword( "" );
            setCurrentPassword( "" );
            setTimeout( onClose, 1500 );
        } catch ( e ) {
            console.warn( e );
        }
    }, [ currentPassword, newPassword, user, onClose ] );

    return (
        <Dialog
            { ...props }
            style={{
                backgroundColor: Colors.WHITE,
                padding: 16,
            }}
        >
            <div style={{ display: "flex", fontSize: 24, color: Colors.GREY, fontWeight: "bold", marginBottom: 16, justifyContent: "center" }}>
                Change Password
            </div>
            <FormGroup label="Current Password" style={{ color: Colors.GREY }}>
                <InputGroup
                    style={{ backgroundColor: Colors.WHITE, color: Colors.GREY }}
                    type={ showCurrentPassword ? undefined : "password" }
                    placeholder="Current Password"
                    value={ currentPassword }
                    onChange={ e => setCurrentPassword( e.target.value ) }
                    rightElement={
                        <BPButton
                            minimal={ true }
                            style={{ color: Colors.GREY }}
                            intent="primary"
                            icon={ showCurrentPassword ? "eye-off" : "eye-open" }
                            onClick={ () => setShowCurrentPassword( v => !v ) }
                        />
                    }
                />
            </FormGroup>
            <FormGroup label="New Password" style={{ color: Colors.GREY }}>
                <InputGroup
                    style={{ backgroundColor: Colors.WHITE, color: Colors.GREY }}
                    type={ showNewPassword ? undefined : "password" }
                    placeholder="New Password"
                    value={ newPassword }
                    onChange={ e => setNewPassword( e.target.value ) }
                    rightElement={
                        <BPButton
                            minimal={ true }
                            style={{ color: Colors.GREY }}
                            intent="primary"
                            icon={ showNewPassword ? "eye-off" : "eye-open" }
                            onClick={ () => setShowNewPassword( v => !v ) }
                        />
                    }
                />
            </FormGroup>
            <div style={{ display: "flex", justifyContent: "center" }}>
                {
                    success ?
                    <span style={{ color: Colors.BUTTON_RED }}>Saved!</span> :
                    <Button
                        backgroundColor={ Colors.BUTTON_RED }
                        textColor={ Colors.WHITE }
                        onClick={ updateUserPassword }
                    >
                        Save
                    </Button>
                }
            </div>
        </Dialog>
    );
};

export const Account = () => {
    const { user } = useAuth();
    const [ userDetails, setUserDetails ] = useState<DatabaseRecord[ CollectionId.Users ]>();
    const [ passwordDialogIsOpen, setPasswordDialogIsOpen ] = useState<boolean>( false );

    useEffect( () => {
        if ( !user ) {
            return;
        }

        getRecord( CollectionId.Users, user.uid ).then( setUserDetails );
    }, [ user ] );

    return (
        <>
            <PasswordChangeDialog isOpen={ passwordDialogIsOpen } onClose={ () => setPasswordDialogIsOpen( false ) } />
            <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", padding: 32 }}>
                <div style={{ fontSize: 24, fontWeight: "bold", color: Colors.GREY, marginBottom: 32 }}>Your Account</div>
                <div style={{ minWidth: 320, display: "flex", flexDirection: "column", fontSize: 16, color: Colors.GREY }}>
                    <table style={{ marginBottom: 16 }}>
                        <tbody>
                            <tr>
                                <td style={{ paddingRight: 16 }}>Email:</td>
                                <td style={{ fontWeight: "bold" }}>{ user?.email }</td>
                            </tr>
                            <tr>
                                <td style={{ paddingRight: 16 }}>Organization:</td>
                                <td style={{ fontWeight: "bold" }}>{ userDetails?.organizationName }</td>
                            </tr>
                        </tbody>
                    </table>
                    <Button backgroundColor={ Colors.BUTTON_RED } textColor={ Colors.WHITE } onClick={ () => setPasswordDialogIsOpen( true ) }>Change Password</Button>
                </div>
            </div>
        </>
    );
};
