import { PacketId } from "./common-types";
import { IPacket } from "./packet.worker";

export enum ExportType {
    CSV,
    Playback,
}

export const exportPlaybackFile = ( packets: IPacket<PacketId>[] ): string => {
    return JSON.stringify( packets );
};

export const loadPlaybackFile = ( playbackFile: string ): IPacket<PacketId>[] => {
    return JSON.parse( playbackFile );
};

export const exportCSV = ( packets: IPacket<PacketId>[] ): string => {
    let out: string = "Timestamp,Battery Current,Battery Voltage,eRPM,Motor Current,Inverter Temperature\n";
    for ( let i = 0; i < packets.length; i++ ) {
        const { id, packet, timestampMs } = packets[ i ];
        if ( id !== PacketId.REALTIME_DATA ) {
            continue;
        }

        const data: IPacket<PacketId.REALTIME_DATA>[ "packet" ] = packet as IPacket<PacketId.REALTIME_DATA>[ "packet" ];

        out += [
            timestampMs.toFixed( 1 ),
            data.batteryCurrent.toString(),
            data.batteryVoltage.toString(),
            data.motorSpeed.toString(),
            data.motorCurrent.toString(),
            data.inverterTemp.toString()
        ].join( "," );

        out += "\n";
    }

    return out;
};

export const downloadText = ( filename: string, text: string ) => {
    const a = document.createElement( "a" );
    a.style.display = "none";
    a.setAttribute( "href", "data:text/plain;charset=utf-8," + encodeURIComponent( text ) );
    a.setAttribute( "download", filename );

    document.body.appendChild( a );
    a.click();

    document.body.removeChild( a );
};

export const exportRecording = ( packets: IPacket<any>[], filename: string, exportType: ExportType ): [ string, string ] | undefined => {
    if ( packets.length === 0 ) {
        return;
    }

    switch ( exportType ) {
        case ExportType.CSV: {
            return [ `${ filename }.csv`, exportCSV( packets ) ];
        }
        case ExportType.Playback: {
            return [ `${ filename }.slnt`, exportPlaybackFile( packets ) ];
        }
    }
};
